import React, { useState, useEffect, useRef } from "react";
import { Banner } from "../../common/banner";
import SimpleReactValidator from "simple-react-validator";
import { submitEnquiry,contactUsAction } from "../../actions";
import { connect } from "react-redux";
import store from "../../store";
import DOMPurify from "dompurify";
import Loader from "../../common/loader";

const CountactUs = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.contactUsAction("contact_us")
    return () => {
      store.dispatch({ type: "RESPONSE_ENQUIRY", payload: "" });
    };
  }, []);

  const [enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [success, setSuccess] = useState();
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (props.enquiry) {
      setEnquiry(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } else setEnquiry(false);
  }, [props.enquiry]);

  const handleChange = (event) => {
    event.persist();
    setEnquiry((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      props.submitEnquiry(enquiry);
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };
// console.log(props.contact)

if(props.contact!=0)
  return (
    <div>
      <Banner
        head="Contact Us"
        image={props.image ? props.image : "/assets/images/church/church8.jpg"}
      />
      <div className="mt-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.7201693443158!2d76.5787587!3d8.905586399999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05fdb15be65f21%3A0xe7ceb9981c9ba88a!2sSt%20Casimir&#39;s%20Church%2C%20Kadavoor!5e0!3m2!1sen!2sin!4v1664808844610!5m2!1sen!2sin"
          className="map-field"
          height="450px"
        ></iframe>
      </div>
      <div className="row mb-4 mx-0 mx-md-5">
        <div className="col-12 col-md-6">
          <h2 className="h3 my-3">Contact Information</h2>
          <div className="bg-light py-5 px-0 px-md-5 contact-form">
            <div className="my-3">
              <b>Address: </b>
              <br />
              <div className="ck-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.contact.contact_us.contact_us_address) }}></div>
            </div>
            <p className="my-3">
              <b>Phone: </b> <a href={"tel://"+props.contact.contact_us.contact_us_number}>{props.contact.contact_us.contact_us_number}</a>
            </p>
            <p className="my-3">
              <b>Email: </b>
              <a href={"mailto:"+props.contact.contact_us.contact_us_email}>{props.contact.contact_us.contact_us_email}</a>
            </p>
            <p className="my-3">
              <b>Website: </b>
              <a href={props.contact.contact_us.contact_us_website_url}  >
              {props.contact.contact_us.contact_us_website_name}        
                    </a>
            </p>
            <p className="my-3">
              <b>Youtube: </b>
              <a href={props.contact.contact_us.contact_us_youtube_url} >
              {props.contact.contact_us.contact_us_youtube_name}        
              </a>
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <h2 className="h3 my-3">Keep Connected</h2>
          <form action="#" className="bg-light py-5 px-0 px-md-5 contact-form">
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Your Name"
                value={enquiry.name}
                onChange={handleChange}
              />
              {validator.current.message(
                "name",
                enquiry.name,
                "required|alpha_space"
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Your Email"
                value={enquiry.email}
                onChange={handleChange}
              />
              {validator.current.message(
                "email",
                enquiry.email,
                "required|email"
              )}
            </div>
            <div className="form-group">
              <textarea
                name="message"
                cols="30"
                rows="7"
                className="form-control"
                placeholder="Message"
                value={enquiry.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-group text-center">
              <input
                type="submit"
                value="Send Message"
                className="btn btn-primary py-3 px-5"
                onClick={handleSubmit}
              />
            </div>
            {success == "" && (
              <div className="alert alert-success" role="alert">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 mr-1"
                  height="30px"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Successfully sent
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
    else return (<Loader></Loader>)
};

const mapStateToProps = (state) => ({
  enquiry: state.utils.enquiry,
  contact: state.content.contact  
});

export default connect(mapStateToProps, { submitEnquiry, contactUsAction })(CountactUs);
