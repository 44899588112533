import React, { useEffect } from "react";

import { aboutUsAction } from "../../actions";
import { Banner } from "../../common/banner";
import { connect } from "react-redux";
import AboutChurch from "./about-church";
import Loader from "../../common/loader";

const AboutUs = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.aboutUsAction("about_us")
  }, []);

  console.log(props.about)
if(props.about.length!=0)
  return (
    <div className="mb-5">
      <Banner
        head="About Us"
        image={props.image ? props.image : "/assets/images/church/church2.jpg"}
      />
      <AboutChurch about={props.about.about_us}/>
    </div>
  );
  else return (<Loader></Loader>)
};

const mapStateToProps = (state) => ({ about: state.content.about });

export default connect(mapStateToProps, { aboutUsAction })(AboutUs);
