import React from "react";

export const SaintQoute = (props) => {
  return (
    <section className="ftco-daily-verse bg-light">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-md-10 daily-verse text-center p-5">
            <span className="flaticon-bible"></span>
            <h3 className="ftco-animate fadeInUp ftco-animated d-none d-md-block">
             {props.quote}
            </h3>
            <h5 className="ftco-animate fadeInUp ftco-animated d-block d-md-none">
             {props.quote}
            </h5>
            <h4 className="h5 mt-4 font-weight-bold ftco-animate fadeInUp ftco-animated">
            {props.name}
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};
