import React from "react";
import { useEffect } from "react";
import { Banner } from "../../common/banner";
import { News } from "../events/news";
import {noticeAction} from "../../actions"
import { connect } from "react-redux";
import Loader from "../../common/loader";

const NoticeBoard = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.noticeAction("notice_board")
  }, []);

  console.log(props.notice)
  if(props.notice!=0)
  return (
    <div>
      <Banner
        head="Notice Board"
        image={props.image ? props.image : "/assets/images/church/church5.jpg"}
      />
      <News head="notice board" desc="" notice={props.notice.notice_board}/>
    </div>
  );
  else return (<Loader></Loader>)
};
const mapStateToProps = (state) => ({ notice: state.content.notice });

export default connect(mapStateToProps, { noticeAction })(NoticeBoard);
