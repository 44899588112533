import React from "react";
import { RecentMass } from "./recent-mass";
import { UpcomingEvents } from "../events/upcoming-events";

export const Events = (props) => {
  console.log("events",props.events)
  const safeEvent = (index) => {
    if (props.events.length > index) {
      console.log("events1",props.events)
      return (
        <UpcomingEvents
          image={props.events[index].events_list_image}
          heading={props.events[index].events_list_heading}
          time={props.events[index].events_list_date}
          description={props.events[index].events_list_description}
        />
      );
    }else
     return null;
  };
  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 py-5">
            <div className="heading-section heading-section-no-line ftco-animate mb-5 fadeInUp ftco-animated">
              <h2 className="mb-2 text-md-left text-center">Upcoming Events</h2>
              <span className="subheading text-md-left text-center">
                Experience God's Presence
              </span>
            </div>
            {safeEvent(0)}
            {safeEvent(1)}
            {safeEvent(2)}
          </div>
          <div className="col-lg-4 ">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-12">
                <div className="d-flex align-items-stretch">
                  <RecentMass />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-12">
                <div className="subsermon opening-hours px-5 pb-5">
                  <h2 className="heading mb-4 ftco-animate fadeInUp ftco-animated text-center py-4">
                    Holy Mass Timing
                  </h2>
                  <p className="mt-3 left">
                    <b>Daily Mass:<br /> Monday to Saturday </b> — <br />
                    <span className="ml-5"> 05:45 Am 06: 30 Am</span>
                  </p>
                  <p className="mt-3  c">
                    <b>All Friday</b> — <br />
                    <span className="ml-5">
                      05.00 Holy Mass Novena St George and Holy Cross
                    </span>
                  </p>
                  <p className="mt-3  text-left">
                    <b>Sunday Mass</b> — <br />
                    <span className="ml-5"> 06.00 Am</span> <br />
                    <span className="ml-5"> 08.30 Am</span> <br />
                    <span className="ml-5"> 05.00 Pm</span>
                  </p>
                  <p className="mt-3  text-left">
                    <b>Confection </b> — <br />
                    <span className="ml-5"> Thursday 05 Pm Onwards</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
