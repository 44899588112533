import React from "react";
import DOMPurify from "dompurify";


export const News = (props) => {
  return (
    <section className="ftco-section bg-light">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-12 text-center heading-section heading-section-light ftco-animate fadeInUp ftco-animated">
            <h2 className="mb-2">
              <span className="px-4">{props.head}</span>
            </h2>
            <span className="subheading">{props.desc}</span>
          </div>
        </div>
        <div className="row d-flex">
        <div  
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.notice.notice_board_content) }}></div>

          {/* {props.images.map((image,i) => ( */}
          {/* <div className="col-lg-12 d-flex ftco-animate fadeInUp ftco-animated d-flex justify-content-center">
            <img
              className="img"
              src={
                `${process.env.PUBLIC_URL}` +
                "/assets/images/church/notice.jpeg"
              }
            />
          </div> */}
          {/* ))} */}
        </div>
      </div>
    </section>
  );
};
