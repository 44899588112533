import React, { useEffect } from "react";

import { hallAction } from "../../actions";
import { Banner } from "../../common/banner";
import { Gallery } from "../../common/gallery";
import  Loader  from "../../common/loader";
import { ConferenceHall } from "./conference-hall";
import { connect } from "react-redux";
import DOMPurify from "dompurify";

const ParishHall = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.hallAction("parish_hall");
  }, []);
  console.log("Conference Hall",props.hall)
  if(props.hall && props.hall.parish_hall)
  return (
    <div className="mb-5">
      <Banner
        head={props.hall.parish_hall.parish_hall_heading?props.hall.parish_hall.parish_hall_heading:"Conference Hall"}
        image={props.image ? props.image : "/assets/images/church/church2.jpg"}
      />
    <div className="container ck-content" style={{padding:"2rem"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.hall.parish_hall.parish_hall_content) }}></div>
    </div>
  );
  else 
  return <Loader></Loader>
};

const mapStateToProps = (state) => ({ hall: state.content.hall });

export default connect(mapStateToProps, { hallAction })(ParishHall);
