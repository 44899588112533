import axios from "axios";

// Preference

export const fetchPreferences = async (tag) => {
    return await axios.get(global.postUrl + "data/" + tag).then(response => {
        console.log("FETCH "+tag,response)
        return response;
    }).catch(error => {
        if (error) {
            return error;
        }
    });
};

// Assets

export const fetchAssets = async (action, url) => {
    return await axios.get(global.postUrl + "assets/" + action + "/" + url).then(response => {
        return response;
    }).catch(error => {
        if (error) {
            return error;
        }
    });
};

// enquiry
export const submitEnquiryService = async (data) => {
    return await axios.post(global.postUrl + "enquiry", data).then(response => {
        return response;
    }).catch(error => {
        if (error) {
            return error;
        }
    });
};

// Analytics
export const submitAnalyticsService = async (data) => {
    return await axios.post(global.postUrl + "event", data).then(response => {
        return response;
    }).catch(error => {
        if (error) {
            return error;
        }
    });
};
