import React from "react";
import { Link } from "react-router-dom";
import { Gallery } from "../../common/gallery";

const ChurchGallery = (props) => {
  console.log("cgallery",props)
  return (
    <section className="ftco-section ftco-gallery pt-0 home-map-background-droped">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="row no-gutters">
              {props.images && 
                props.images.gallery_list.filter((v,c)=>c<4).map((image, i) => (
                  <Gallery image={image.gallery_list_image} index={i} column={6} />
                ))}

              {/* dummy content  */}

              {/* <div
                className={"ftco-animate fadeInUp ftco-animated p-1 col-md-6"}
              >
                <Link to={`${process.env.PUBLIC_URL}/gallery`}>
                  <div
                    className="gallery image-popup img d-flex align-items-center"
                    style={{
                      backgroundImage: "url(/assets/images/church/church1.jpg)",
                    }}
                  ></div>
                </Link>
              </div>
              <div
                className={"ftco-animate fadeInUp ftco-animated p-1 col-md-6"}
              >
                <Link to={`${process.env.PUBLIC_URL}/gallery`}>
                  <div
                    className="gallery image-popup img d-flex align-items-center"
                    style={{
                      backgroundImage: "url(/assets/images/church/church2.jpg)",
                    }}
                  ></div>
                </Link>
              </div>
              <div
                className={"ftco-animate fadeInUp ftco-animated p-1 col-md-6"}
              >
                <Link to={`${process.env.PUBLIC_URL}/gallery`}>
                  <div
                    className="gallery image-popup img d-flex align-items-center"
                    style={{
                      backgroundImage: "url(/assets/images/church/church3.jpg)",
                    }}
                  ></div>
                </Link>
              </div>
              <div
                className={"ftco-animate fadeInUp ftco-animated p-1 col-md-6"}
              >
                <Link to={`${process.env.PUBLIC_URL}/gallery`}>
                  <div
                    className="gallery image-popup img d-flex align-items-center"
                    style={{
                      backgroundImage: "url(/assets/images/church/church4.jpg)",
                    }}
                  ></div>
                </Link>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 d-flex">
            <iframe
              className="map-field"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.7201693443158!2d76.5787587!3d8.905586399999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05fdb15be65f21%3A0xe7ceb9981c9ba88a!2sSt%20Casimir&#39;s%20Church%2C%20Kadavoor!5e0!3m2!1sen!2sin!4v1664808844610!5m2!1sen!2sin"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChurchGallery;
