import React from "react";
import DOMPurify from "dompurify";


export const Biography = (props) => {
  return (
    <section className="ftco-section bg-light py-5">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-12 text-center heading-section ftco-animate">
            <h2 className="mb-2">
              <span className="bg-light px-4">Biography</span>
            </h2>
            <span className="subheading">Message from great one's</span>
          </div>
        </div>
        <div className="row d-flex sermon-wrap mb-5">
        <div  
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.biography.saint_biography_content) }}></div>

          {/* <div className="col-md-4 d-flex align-items-stretch ftco-animate">
            <img className="img" src="/assets/images/church/saint/saint.jpg" />
          </div>
          <div className="col-md-8 text ftco-animate">
      
            <h2 className="mb-2 text-md-left text-center">
              Pilgrim Shrine of Holy Cross and St. George
            </h2>
            <p className="text-justify">
              Located on the shores of the enchanting Ashtamudi lake of
              spectacular scenic beauty, St. Casmir’s Church, Kadavoor , a
              spiritual edifice with architectural splendor, stands elegantly as
              a site of ancient religious heritage and tradition coupled with a
              rich legacy of faith of the Catholic community.
            </p>
            <p className="text-justify">
              St. Thomas, one of the twelve Apostles of Jesus, arrived in Kerala
              in 54 A.D and he is said to have established seven Churches, of
              which one is said to be in Kollam. The Christian Community of
              Kadavoor is believed to be a remnant of that ancient community of
              faithful established by the First Apostle of India. As Kollam was
              an ancient port town, missionaries also came along with the
              foreign traders and merchants from different parts of the world.
              Franciscan and Dominican missionaries strove hard to kindle and
              sustain Christian faith in this region.
            </p>
            <p className="text-justify">
              It is recorded that in the year 1321, the French Dominican
              missionary Jordanus Catalani of venerable memory started a mission
              in Thevally region of the present Kadavoor parish and built a
              church in the name of St. George. Adjacent to this place of
              worship, there already existed a Church built in honour of Blessed
              Virgin Mary called “Tharissa Pally”. Bishop Jordanus Catalani, the
              first Bishop of Quilon was instrumental in bringing the people
              from the Nestorian schismatic faith orientations and practices to
              Roman Catholic faith and pristine Latin traditions of liturgy.
            </p>
          </div> */}
        </div>
      </div>
    </section>
  );
};
