import React from "react";
import { Link } from "react-router-dom";

import previous from "./live.json";

export const PreviousMass = (props) => {

  return (
    <section className="ftco-section pt-5">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-12 text-center heading-section ftco-animate fadeInUp ftco-animated">
            <h2 className="mb-2">
              <span className="px-4">Previous Mass</span>
            </h2>
            <span className="subheading">Experience God's Presence</span>
          </div>
        </div>
        <div className="row">
          {props.list
            ? props.list
            : previous.map((previous, i) => (
                <div className="col-md-4" key={i}>
                  <div className="sermon-wrap sermon-wrap-2 mb-4 ftco-animate fadeInUp ftco-animated">
                    <Link
                      to={`${process.env.PUBLIC_URL}/live/` + previous.link}
                      onClick={window.scrollTo(0, 0)}
                      className="img"
                      style={{
                        backgroundImage:
                          "url(" +
                          `${process.env.PUBLIC_URL}` +
                          previous.image +
                          ")",
                      }}
                    ></Link>
                    <div className="text p-4 bg-light text-center">
                      <div className="meta">
                        <p>
                          <span>Date: {previous.date}</span>
                        </p>
                      </div>
                      <Link
                        to={`${process.env.PUBLIC_URL}/live/` + previous.link}
                        onClick={window.scrollTo(0, 0)}
                        className="btn-custom mb-2 p-2 text-center popup-vimeo"
                      >
                        <span className="icon-play"></span> Watch Mass
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </section>
  );
};
