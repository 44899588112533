import React, { useEffect } from "react";
import { Banner } from "../../common/banner";
import { committeeAction } from "../../actions";
import { connect } from "react-redux";
import { MembersList } from "./member-list";
import Loader from "../../common/loader";

const CommiteeMembers = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.committeeAction("committee_members")
  }, []);

  console.log(props.committee)

if(props.committee!=0)
  return (
    <div>
      <Banner
        head="Committee Members"
        image={props.image ? props.image : "/assets/images/church/church6.jpg"}
      />
      <MembersList  head=" Ajapalana Samathi" desc="St.Casimir's Kadavoor" committee={props.committee.committee_members}/>
    </div>
  );
  else return (<Loader></Loader>)
};

const mapStateToProps = (state) => ({ committee: state.content.committee });

export default connect(mapStateToProps, { committeeAction })(CommiteeMembers);
