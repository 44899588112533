import React from "react";

export const TodayLive = (props) => {
  return (
    <section className="ftco-section bg-light pb-5">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-12 text-center heading-section heading-section-light ftco-animate fadeInUp ftco-animated">
            <h2 className="mb-2">
              <span className="px-4">Watch live holy mass</span>
            </h2>
            <span className="subheading">Experience God's Presence</span>
          </div>
        </div>
        <div className="row d-flex sermon-wrap">
          <div className="col-12">
            {props.youtube ? (
              <div>
                <div
                  style={{
                    paddingBottom: "56.25%",
                    position: "relative",
                    display: "block",
                    width: "100%",
                  }}
                >
                  <iframe
                    id="player"
                    width="100%"
                    height="100%"
                    style={{ position: "absolute", top: 0, left: 0 }}
                    src={"https://www.youtube.com/embed/" + props.youtube}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                {/* <iframe
                    width="100%"
                    src={"https://www.youtube.com/embed/" + props.youtube}
                    title="YouTube video"
                    allowfullscreen
                  ></iframe> */}
                <div className=" col-12 pt-5 pb-4 text-center ">
                  <a
                    className="mt-4 btn-customize"
                    href={"https://www.youtube.com/embed/" + props.youtube}
                    target="blank"
                  >
                    <div className="btn btn-primary px-4 py-3 mr-md-2">
                      <span className="icon-play mr-2"></span>
                      Watch on full screen
                    </div>
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <h3 className="text-center">  No live mass soon</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
