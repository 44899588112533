import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Banner } from "../../common/banner";
import { PreviousMass } from "./previous-mass";
import { TodayLive } from "./today-live";
import {liveAction} from "../../actions"
import { connect } from "react-redux";
import Loader from "../../common/loader";

const LiveStream = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.liveAction("live")
  }, []);

  let { id } = useParams();

  // console.log(props.live)
if(props.live!=0)
  return (
    <div>
      <Banner
        head="Live Streaming"
        image={props.image ? props.image : "/assets/images/church/church2.jpg"}
      />
      <TodayLive
        youtube={id ? id : props.live.live_gallery_current.live_gallery_current_youtube_id}
      />
      <PreviousMass
        image="/assets/images/church/church10.jpeg"
        heading="Pray for Leaders"  
        date="date"
        list={props.live.live_gallery_current.live_gallery_list}
      />
    </div>
  );
  else return (<Loader></Loader>)
};

const mapStateToProps = (state) => ({ live: state.content.live });

export default connect(mapStateToProps, { liveAction })(LiveStream);
