import React from "react";
import { useEffect } from "react";
import { Banner } from "../../common/banner";
import History from "./history";
import {churchHistoryAction} from "../../actions"
import { connect } from "react-redux";
import Loader from "../../common/loader";


const ChurchHistory = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.churchHistoryAction("church_history")
  }, []);

  // console.log(props.history)
  if(props.history!=0)
  return (
    <div>
      <Banner
        head="Church History"
        image={props.image ? props.image : "/assets/images/church/church2.jpg"}
      />
      <History history={props.history.church_history}/>
    </div>
  );
  else return (<Loader></Loader>)
};
const mapStateToProps = (state) => ({ history: state.content.history });

export default connect(mapStateToProps, { churchHistoryAction })(ChurchHistory);
