import React from "react";

export const Banner = (props) => {
  return (
    <div
      className="parallax"
      style={{
        backgroundImage:
          "url(" + `${process.env.PUBLIC_URL}` + props.image + ")",
        height: "25rem",
        backgroundPosition: "50% -25px",
      }}
      data-stellar-background-ratio="0.5"
    >
      <div className="overlay"></div>
      <div className="container">
        <div
          className="row no-gutters slider-text js-fullheight align-items-center justify-content-center"
          data-scrollax-parent="true"
          style={{ height: "25rem" }}
        >
          <div
            className="col-md-10 text-center ftco-animate fadeInUp ftco-animated"
            data-scrollax={{ properties: { translateY: "70%" } }}
            style={{ transform: "translateZ(0px) translateY(0%)" }}
          >
            <h1 className="mt-5 banner-heading">{props.head}</h1>

            <h3 className="subheading mb-4 pb-1">
              {props.description ? props.description : ""}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
