import { combineReducers } from "redux";
import AssetReducer from "./assets";
import ContentReducer from "./content";
import UtiltReducer from "./utils";

const rootReducer = combineReducers({
  content: ContentReducer,
  asset: AssetReducer,
  utils:UtiltReducer
});

export default rootReducer;
