import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <footer className="ftco-footer ftco-bg-dark ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">
                St. Casimir’s Church <br />
              </h2>
              <p className="text-justify">
                {props.message
                  ? props.message
                  : "Located on the shores of the enchanting Ashtamudi lake of spectacular scenic beauty, St. Casmir’s Church, Kadavoor"}
              </p>
              <ul className="ftco-footer-social list-unstyled mt-5">
                <li className="ftco-animate">
                  <a href="https://www.twitter.com">
                    <span className="icon-twitter"></span>
                  </a>
                </li>
                <li className="ftco-animate">
                  <a href="https://www.facebook.com">
                    <span className="icon-facebook"></span>
                  </a>
                </li>
                <li className="ftco-animate">
                  <a href="https://www.instagram.com">
                    <span className="icon-instagram"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4 ml-md-5">
              <h2 className="ftco-heading-2 text-left">Navigation</h2>
              <ul className="list-unstyled">
                <li>
                  <Link to="/live" className="py-1 d-block text-left">
                    <span className="ion-ios-arrow-forward mr-3"></span>Live
                    Holy Mass
                  </Link>
                </li>
                <li>
                  <Link to="/events" className="py-1 d-block text-left">
                    <span className="ion-ios-arrow-forward mr-3"></span>Events
                  </Link>
                </li>
                <li>
                  <Link to="/church-history" className="py-1 d-block text-left">
                    <span className="ion-ios-arrow-forward mr-3"></span>Church
                    History
                  </Link>
                </li>
                <li>
                  <Link
                    to="/committee-members"
                    className="py-1 d-block text-left"
                  >
                    <span className="ion-ios-arrow-forward mr-3"></span>
                    Committee members
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" className="py-1 d-block text-left">
                    <span className="ion-ios-arrow-forward mr-3"></span>Gallery
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4 ml-md-5">
              <h2 className="ftco-heading-2 text-left">Navigation</h2>
              <ul className="list-unstyled">
                <li>
                  <Link to="/parish-hall" className="py-1 d-block text-left">
                    <span className="ion-ios-arrow-forward mr-3"></span>Parish
                    Hall
                  </Link>
                </li>
                <li>
                  <Link to="/notice-board" className="py-1 d-block text-left">
                    <span className="ion-ios-arrow-forward mr-3"></span>Notice
                    Board
                  </Link>
                </li>
                <li>
                  <Link
                    to="/saint-biography"
                    className="py-1 d-block text-left"
                  >
                    <span className="ion-ios-arrow-forward mr-3"></span>Saint
                    Biography
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className="py-1 d-block text-left">
                    <span className="ion-ios-arrow-forward mr-3"></span>Contact
                    Us
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="py-1 d-block text-left">
                    <span className="ion-ios-arrow-forward mr-3"></span>About Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2 text-left">Service Hours</h2>
              <div className="opening-hours">
                <p className="text-left text-justify pt-1">
                    St Casimir Church Kadavoor
                    <br />
                    Mathilil. P. O,
                    <br />
                    Kollam Kerala State.
                    <br />
                    691601
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p>
              Copyright ©
              <script>document.write(new Date().getFullYear());</script>2022 All
              rights reserved by St. Casimir's Church |{" "}
              <a href="https://www.skymerinnovations.com/" target="_blank">
                Skymer Innovations
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
