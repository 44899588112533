import React from "react";
import { Link } from "react-router-dom";

export const NextEvent = (props) => {
  return (
    <div className="d-inline-flex ftco-intro" id="next-event-scroll">
      <div
        style={{
          backgroundImage: "url(" + props.image2 + ")",
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="col-md-6 col-12 px-0 px-sm-5 py-4 ftco-animate fadeInUp ftco-animated d-flex flex-column justify-content-center"
      >
        <h2 className="subheading text-white text-center next-event">
          Welcome note
        </h2>
        <h2 className="text-white text-center next-event">{props.message}</h2>
      </div>
      <div className="overlay"></div>
      <div
        //  className="ftco-intro py-5"
        style={{
          backgroundImage: "url(" + props.image1 + ")",
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="col-md-6 col-12 px-3 pl-md-5 ftco-animate fadeInUp ftco-animated d-none d-md-block"
      >
        {/* <div id="timer" className="d-flex mb-3 justify-content-center">
            <div className="time pl-4" id="hours"></div>
            <div className="time pl-4" id="minutes"></div>
            <div className="time pl-4" id="seconds"></div>
          </div> */}
        <h1 className="text-white text-center mt-5">St. George’s Shrine</h1>
        <div className="d-flex justify-content-center">
          <Link to="/saint-biography" className="btn btn-primary px-4 py-3">
            <p className="mb-0">Read more</p>
          </Link>
        </div>
      </div>
    </div>
  );
};
