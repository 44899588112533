import {
    RESPONSE_ENQUIRY,
    FAILED_ENQUIRY
} from "../constants/actionTypes"

const initialState = {
    enquiry: ""
}

export default function UtiltReducer(state = initialState, action) {
    switch (action.type) {
        case RESPONSE_ENQUIRY:
            return {
                ... state,
                enquiry: true
            }
        case FAILED_ENQUIRY:
            return {
                ... state,
                enquiry: false
            }
        default:
            return {
                ... state
            }
    }

}
