import React from "react";

export const ConferenceHall = (props) => {
  var year = "2012";
  return (
    <section className="ftco-counter mt-5 pb-5" id="section-counter">
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-6 d-flex flex-column align-items-stretch">
            <div
              className="img d-flex align-self-stretch align-items-center justify-content-center"
              style={{
                backgroundImage:
                  "url(" +
                  "https://server.stcasimirchurchkadavoor.com/" +
                  props.hall.home_conference_first_photo +")",
              }}
            ></div>
            <div
              className="img d-flex align-self-stretch align-items-center justify-content-center"
              style={{
                backgroundImage:
                  "url(" +
                  "https://server.stcasimirchurchkadavoor.com/" +
                  props.hall.home_conference_second_photo +")",
              }}
            ></div>
          </div>
          <div className="col-md-6 px-5 order-last">
            <div className="row pt-3 pb-3">
              <div className="col-md-12 heading-section heading-section-no-line ftco-animate fadeInUp ftco-animated">
                <h2 className="mb-4 text-center text-md-left">
                 {props.hall.home_conference_hall_title?props.hall.home_conference_hall_title:"CONFERENCE HALL"}
                </h2>
                <span className="subheading text-center text-md-left">
                {props.hall.home_conference_hall_subtitle?props.hall.home_conference_hall_subtitle:"The new One"}
                </span>

                <p className="mt-4 text-justify">
                  {props.hall.home_conference_hall_message
                    ? props.hall.home_conference_hall_message
                    : "Located on the shores of the enchanting Ashtamudi lake of spectacular scenic beauty, St.Casmir’s Church, Kadavoor"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
                <div className="block-18 text-center py-4 bg-light mb-4">
                  <div className="text">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        height="30px"
                      >
                        <path d="M248 48V256h48V58.7c23.9 13.8 40 39.7 40 69.3V256h48V128C384 57.3 326.7 0 256 0H192C121.3 0 64 57.3 64 128V256h48V128c0-29.6 16.1-55.5 40-69.3V256h48V48h48zM48 288c-12.1 0-23.2 6.8-28.6 17.7l-16 32c-5 9.9-4.4 21.7 1.4 31.1S20.9 384 32 384l0 96c0 17.7 14.3 32 32 32s32-14.3 32-32V384H352v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384c11.1 0 21.4-5.7 27.2-15.2s6.4-21.2 1.4-31.1l-16-32C423.2 294.8 412.1 288 400 288H48z" />
                      </svg>
                      <i className="fa fa-chair"></i>
                    </div>
                    <strong className="number" data-number="470">
                    {props.numbers.home_numbers_seats?props.numbers.home_numbers_seats:470}
                      {/* 470 */}
                    </strong>
                    <span>No. of Seats</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
                <div className="block-18 text-center py-4 bg-light mb-4">
                  <div className="text">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        height="30px"
                      >
                        <path d="M173.8 5.5c11-7.3 25.4-7.3 36.4 0L228 17.2c6 3.9 13 5.8 20.1 5.4l21.3-1.3c13.2-.8 25.6 6.4 31.5 18.2l9.6 19.1c3.2 6.4 8.4 11.5 14.7 14.7L344.5 83c11.8 5.9 19 18.3 18.2 31.5l-1.3 21.3c-.4 7.1 1.5 14.2 5.4 20.1l11.8 17.8c7.3 11 7.3 25.4 0 36.4L366.8 228c-3.9 6-5.8 13-5.4 20.1l1.3 21.3c.8 13.2-6.4 25.6-18.2 31.5l-19.1 9.6c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-11 7.3-25.4 7.3-36.4 0L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7L39.5 301c-11.8-5.9-19-18.3-18.2-31.5l1.3-21.3c.4-7.1-1.5-14.2-5.4-20.1L5.5 210.2c-7.3-11-7.3-25.4 0-36.4L17.2 156c3.9-6 5.8-13 5.4-20.1l-1.3-21.3c-.8-13.2 6.4-25.6 18.2-31.5l19.1-9.6C65 70.2 70.2 65 73.4 58.6L83 39.5c5.9-11.8 18.3-19 31.5-18.2l21.3 1.3c7.1 .4 14.2-1.5 20.1-5.4L173.8 5.5zM272 192c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80s80-35.8 80-80zM1.3 441.8L44.4 339.3c.2 .1 .3 .2 .4 .4l9.6 19.1c11.7 23.2 36 37.3 62 35.8l21.3-1.3c.2 0 .5 0 .7 .2l17.8 11.8c5.1 3.3 10.5 5.9 16.1 7.7l-37.6 89.3c-2.3 5.5-7.4 9.2-13.3 9.7s-11.6-2.2-14.8-7.2L74.4 455.5l-56.1 8.3c-5.7 .8-11.4-1.5-15-6s-4.3-10.7-2.1-16zm248 60.4L211.7 413c5.6-1.8 11-4.3 16.1-7.7l17.8-11.8c.2-.1 .4-.2 .7-.2l21.3 1.3c26 1.5 50.3-12.6 62-35.8l9.6-19.1c.1-.2 .2-.3 .4-.4l43.2 102.5c2.2 5.3 1.4 11.4-2.1 16s-9.3 6.9-15 6l-56.1-8.3-32.2 49.2c-3.2 5-8.9 7.7-14.8 7.2s-11-4.3-13.3-9.7z" />
                      </svg>
                    </div>
                    <strong className="number" data-number="2012">
                    {props.numbers.home_numbers_innugrated?props.numbers.home_numbers_innugrated:2012}

                      {/* 2012 */}
                    </strong>
                    <span>Innuaguated on</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
                <div className="block-18 text-center py-4 bg-light mb-4">
                  <div className="text">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        height="30px"
                      >
                        <path d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40s17.9 40 40 40z" />
                      </svg>
                    </div>
                    <strong className="number" data-number="10000">
                      {props.numbers.home_numbers_sqaure_feet?props.numbers.home_numbers_sqaure_feet:10000}

                      {/* 10000 */}
                    </strong>
                    <span>Square feet</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
                <div className="block-18 text-center py-4 bg-light mb-4">
                  <div className="text">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        height="30px"
                      >
                        <path d="M160 0V64H288V0h64V64h96v96H0V64H96V0h64zM0 192H448V512H0V192zM64 320h64V256H64v64zm128-64v64h64V256H192zm192 64V256H320v64h64zM64 448h64V384H64v64zm192-64H192v64h64V384zm64 64h64V384H320v64z" />
                      </svg>
                    </div>
                    <strong className="number" data-number="280">
                    {props.numbers.home_numbers_events?props.numbers.home_numbers_events:280}

                      {/* 280+ */}
                    </strong>
                    <span>No. of Events</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
