import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk'

import rootReducer from '../reducer';

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) 
            return undefined
        
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

const persistedState = loadFromLocalStorage()

const store = createStore(rootReducer, persistedState, compose(applyMiddleware(thunkMiddleware)));

export default store;
