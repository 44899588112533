import React from "react";
import { Gallery } from "../../common/gallery";
import commitee from "./committe.json"
import DOMPurify from "dompurify";


export const MembersList = (props) => {
  return (
    <section className="ftco-section bg-light">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-12 text-center heading-section heading-section-light ftco-animate fadeInUp ftco-animated">
            <h2 className="mb-2">
              <span className="px-4">{props.head}</span>
            </h2>
            <span className="subheading">{props.desc}</span>
          </div>
        </div>
        <div className="col-md-12 mb-5"
           dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.committee.committee_members_content) }}></div>
        {/* <div className="row">
              {props.images
                ? props.images
                : commitee.map((image, i) => (
                      <Gallery image={image} index={i} column={6} />
                    ))}
            </div> */}
        {/* <div className="row d-flex d-flex justify-content-center mt-5">
          
          <div className="col-lg-11 d-flex ftco-animate fadeInUp ftco-animated">
            <ul>
              <li>
                കൈക്കാരൻ: <b>ഇഗ്നേഷ്യസ് ഏലിയാസ്</b>
              </li>
              <li>
                ധനകാര്യ സമിതി സെക്രട്ടറി: <b>മിൽട്ടൺ.ജി</b>
              </li>
              <li>
                ധനകാര്യ സമിതി അംഗം: <b>ജോൺ ഫ്രാൻസിസ്</b>
              </li>
              <li>
                അജപാലന സമിതി സെക്രട്ടറി: <b>വർഗ്ഗീസ് എ</b>
              </li>
              <li>
                കോ ഓർഡിനേറ്റർ: <b>ആൻ്റണി കസ്മേൽ </b>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </section>
  );
};
