import React from "react";
import services from "./services-list.json";

export const Services = (props) => {
  return (
    <section className="ftco-section py-5">
      <div className="container">
        <div className="row justify-content-center mb-md-5 pb-3">
          <div className="col-md-12 text-center heading-section ftco-animate fadeInUp ftco-animated">
            <h2 className="mb-2">
              <span>Church Services</span>
            </h2>
            <span className="subheading">St.Casimir's Kadavoor</span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            {props.service
              ? props.service
              : services.filter(ser=>ser.id%2==1).map((service, i) => (
                  <div
                    className="d-flex services ftco-animate"
                    key={i}
                  >
                    <div className="icon order-lg-last d-flex align-items-center justify-content-center">
                      <span className={service.icon}></span>
                    </div>
                    <div className="media-body pr-lg-5">
                      <h3 className="heading mb-3 text-justify text-lg-right"> {service.heading}</h3>
                      <p className="text-justify text-lg-right">{service.description}</p>
                    </div>
                  </div>
                ))}
          </div>
          <div className="col-lg-6">
            {props.service
              ? props.service
              : services.filter(ser=>ser.id%2==0).map((service, i) => (
                <div
                    className="d-flex services ftco-animate"
                    key={i}
                  >
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className={service.icon}></span>
                    </div>
                    <div className="media-body pl-lg-5">
                      <h3 className="heading mb-3">{service.heading}</h3>
                      <p className="text-justify">{service.description}</p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </section>
  );
};
