import React from "react";
import DOMPurify from "dompurify";

const AboutChurch = (props) => {
  return (
    <section className="ftco-section py-5">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12 text-center heading-section ftco-animate fadeInUp ftco-animated">
            <h2 className="mb-2">
              <span className="px-4">{props.about.about_us_heading}</span>
            </h2>
            <span className="subheading">St.Casimir's Kadavoor</span>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          {/* {props.about &&
            props.about.map((about, i) => (
              <div className="col-md-12 mb-5 " key={i}>
                <h3 className="mb-4">{about.heading}</h3>
                <p className="text-justify">{about.description}</p>

                <div className="row mb-5">
                  <div className="col-12 col-md-6 p-2">
                    <img src={about.image1} width="100%" />
                  </div>
                  <div className="col-12 col-md-6 p-2">
                    <img src={about.image2} width="100%" />
                  </div>
                </div>
                <hr className="my-5" />
              </div>
            ))} */}

          <div className="col-md-12 mb-5 cms-container"
           dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.about.about_us_content) }}></div>

          {/* Dummy content  */}

          {/* <div className="col-md-12 mb-5">
            <h3 className="mb-4">
              Pilgrim Shrine of Holy Cross and St. George
            </h3>
            <p className="text-justify">
              Located on the shores of the enchanting Ashtamudi lake of
              spectacular scenic beauty, St. Casmir’s Church, Kadavoor , a
              spiritual edifice with architectural splendor, stands elegantly as
              a site of ancient religious heritage and tradition coupled with a
              rich legacy of faith of the Catholic community.
            </p>
            <p className="text-justify">
              St. Thomas, one of the twelve Apostles of Jesus, arrived in Kerala
              in 54 A.D and he is said to have established seven Churches, of
              which one is said to be in Kollam. The Christian Community of
              Kadavoor is believed to be a remnant of that ancient community of
              faithful established by the First Apostle of India. As Kollam was
              an ancient port town, missionaries also came along with the
              foreign traders and merchants from different parts of the world.
              Franciscan and Dominican missionaries strove hard to kindle and
              sustain Christian faith in this region.
            </p>
            <p className="text-justify">
              It is recorded that in the year 1321, the French Dominican
              missionary Jordanus Catalani of venerable memory started a mission
              in Thevally region of the present Kadavoor parish and built a
              church in the name of St. George. Adjacent to this place of
              worship, there already existed a Church built in honour of Blessed
              Virgin Mary called “Tharissa Pally”. Bishop Jordanus Catalani, the
              first Bishop of Quilon was instrumental in bringing the people
              from the Nestorian schismatic faith orientations and practices to
              Roman Catholic faith and pristine Latin traditions of liturgy.
            </p>
            <hr className="my-5" />
            <h3 className="mb-4">Historical ‘Muthappan Cross ‘</h3>
            <p className="text-justify">
              When the Portugeese missionaries arrived in 1502, there was a
              significant revival of faith. At that time there were about 25000
              christians in Kollam. In the religious persecution unleashed by
              religious fanatics in Kollam in the later periods, about 9 priests
              and 3000 Christians were killed and they became martyrs of faith.
              A stone Cross was erected in Kadavoor church premises to
              commemorate this martyrdom of great number of people and priests
              and subsequently this cross was widely venerated by the faithful
              christening it as ‘Muthappan Cross’ . It was originally erected
              for worship by the faithful in Kadavoor who numbered around 100 at
              the time of its establishment. This cross attracts a large number
              of devotees daily who light candles and oil lamps in this Cross of
              worship and adoration. Blessings abound here and the devotees from
              all sects and faiths have many testimonies to support their ardent
              devotion to the miraculous Cross.
            </p>
            <hr className="my-5" />
            <h3 className="mb-4">St. Casimir’s Church</h3>
            <p className="text-justify">
              This is the first and until now the only Church in India dedicated
              to the Catholic Saint Casimir Jagiellon, a prince of the Kingdom
              of Poland and of the grand Duchy of Lithuania known for his piety
              and generosity.
            </p>
            <p className="text-justify">
              In the year 1870, the Vicar Apostolic of Quilon Bishop Maria
              Ephrem Garrilon established the present parish of Kadavoor and
              appointed Rev. Fr. Diog Anto Ivan D’Souza as first parish priest
              of Kadavoor. In 1873, the construction of the church was completed
              .Again it was expanded and renovated in 1962. The Church was
              reconstructed again and opened for worship on 23 April, 1993.
            </p>
            <div className="row mb-5">
              <div className="col-12 col-md-6 p-2">
                <img src="/assets/images/church/church6.jpg" width="100%" />
              </div>
              <div className="col-12 col-md-6 p-2">
                <img src="/assets/images/church/church1.jpg" width="100%" />
              </div>
            </div>
            <hr className="my-5" />

            <h3 className="mb-4">Relics of St. Casimir</h3>
            <p className="text-justify">
              Relics of S. Casimir was brought from Rome and it was placed
              permanently for public veneration on 17 April,2013 in the Church
              at Kadavoor on the occasion of the blessing of the further
              renovated church by Bishop Stanley Roman . Church renovation
              project was sponsored by Shri. P .P. Henry and family, Puthenvila
              Poomangalathu, Kadavoor.
            </p>
            <hr className="my-5" />
            <h3 className="mb-4">St. George’s Shrine</h3>
            <p className="text-justify">
              Devotion to St. George was prevalent from a very early period in
              Kadavoor. A sacred picture of St. George was installed as early as
              in 1916 in the first shrine built in honour of St. George that was
              blessed by Arch Bishop Aloysius Maria Benziger.
            </p>
            <p className="text-justify">
              The present shrine was built in the year 1960 under the
              sponsorship of Shri. A.V. Fernandez, proprietor of St. George Oil
              Mill. It was blessed by late Rt. Rev. Jerome Fernandez, the then
              Bishop of Quilon. Countless number of faithful flock to this
              shrine for healing and divine blessings especially on Fridays.
            </p>
            <hr className="my-5" />
            <h3 className="mb-4">
              Little Flower Chapel, Kadavoor- Kiszhakkekara.
            </h3>
            <p className="text-justify">
              Little Flower Chapel was built with the donation of Shri. A.V.
              Fernandez and blessed on October 1, 1963.
            </p>
            <hr className="my-5" />
            <h3 className="mb-4">Ecce Homo Shrine, Kadavoor- Kizhakkekara</h3>
            <p className="text-justify">
              This shrine was built with the donation of Shri. V.A. Nazareth and
              it was blessed on 18 April, 1997.
            </p>
            <p className="text-justify">
              Thus, St. Casimir’s Church, Kadavoor and the associated Holy
              Shrines of St. George and Holy Cross (Muthappan Cross) stand as
              sacred symbols of faith offering refuge, solace and hope for all
              people irrespective of caste and creed.
            </p>
            <hr className="my-5" />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default AboutChurch;
