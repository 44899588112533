import React from "react";
import { Link } from "react-router-dom";

export const HomeBanner = (props) => {
  const scrollDown = () => {
    var ele = document.getElementById("next-event-scroll");
    window.scrollTo({
      top: ele.offsetTop - 49,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="parallax"
      style={{
        backgroundImage: "url(" + props.image + ")",
        height: "51rem",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="overlay"></div>
      <div className="container">
        <div
          className="row no-gutters slider-text js-fullheight align-items-center justify-content-center"
          data-scrollax-parent="true"
          style={{ height: "51rem" }}
        >
          <div
            className="col-md-10 text-center ftco-animate fadeInUp ftco-animated"
            data-scrollax=" properties: { translateY: '70%' }"
            style={{ transform: "translateZ(0px) translateY(0%)" }}
          >
            <h1 className="mt-5 banner-heading">
              St. Casimir's Church
              <span>Kadavoor</span>
            </h1>
            <h3 className="subheading mb-4 pb-1">
              {props.description ? props.description : ""}
            </h3>
            <Link
              to={`${process.env.PUBLIC_URL}/live`}
              className="btn btn-white py-3 px-4"
            >
              <div>
                <span className="icon-play-circle"></span>
                <b> Live Stream</b>
              </div>
            </Link>
            <div className="mouse d-none d-lg-block">
              <div className="mouse-icon" onClick={() => scrollDown()}>
                <div className="mouse-wheel">
                  <span className="ion-ios-arrow-down"></span>
                </div>
              </div>
            </div>
            {/* <button onclick="scroll(0, 100);">
              <div className="mouse-wheel">
                <span className="ion-ios-arrow-down"></span>
              </div>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
