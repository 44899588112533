import React from "react";

export const UpcomingEvents = (props) => {
  return (
    <div key={props.index}>
      <div className="event-wrap d-md-flex ftco-animate fadeInUp ftco-animated">
        <div
          className="img"
          style={{
            backgroundImage:
              "url(" + "https://server.stcasimirchurchkadavoor.com/og/"  + props.image + ")",
          }}
        ></div>
        <div className="text pl-md-5 text-md-left text-center">
          <h2 className="mb-3">{props.heading ? props.heading : "heading"}</h2>
          <div className="meta">
            <p>
              <span>
                <i className="icon-calendar mr-2"></i>
                {props.time ? props.time : "time"}
              </span>
            </p>
          </div>
          <p className="text-justify">
            {props.description
              ? props.description
              : "Lorem ipsum dolor sit amet, consectetur adipisicing elit Ducimus itaque, autem necessitatibus voluptate quod Ducimus itaque, autem necessitatibus voluptate quod"}
          </p>
        </div>
      </div>
    </div>
  );
};
