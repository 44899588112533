import React, { useState } from "react";
import LargeImage from "./large-image";

export const Gallery = (props) => {

  return (
    <div
      className={
        "ftco-animate fadeInUp ftco-animated p-1 col-md-" + props.column
      }
      key={props.index}
    >
      <img
        className="img"
        alt=""
        width="100%"
        src={  "https://server.stcasimirchurchkadavoor.com/" +props.image}
        onClick={() =>{
          if(window.innerWidth>767)
           props.setPopup(props.image)}
          }
      />
      {/* <LargeImage popup={popup} image={props.image} setPopup={setPopup} /> */}
    </div>
  );
};
