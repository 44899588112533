import React from "react";
import { Link } from "react-router-dom";

export const RecentMass = () => {
  return (
    <div className="subsermon p-5">
      <h2 className="heading mb-5 ftco-animate fadeInUp ftco-animated">
        Recent Mass
      </h2>
      <div className="sermon-wrap mb-4 ftco-animate fadeInUp ftco-animated">
        <div
          className="img mb-3"
          style={{ backgroundImage: "url("+`${process.env.PUBLIC_URL}` +"/assets/images/event-2.jpeg)" }}
        ></div>
        <div className="text">
          <p className="mt-4">
            <Link
              to="/live"
              className="btn-custom  p-2 text-center popup-vimeo"
            >
              <span className="icon-play mr-2"></span>
              Watch Mass
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
