
// fetch home
export const FETCH_HOME = "FETCH_HOME"
export const RECIEVE_PREFERENCES_HOME = "RECIEVE_PREFERENCES_HOME"
export const FAILED_PREFERENCES_HOME = "FAILED_PREFERENCES_HOME" 
export const RECIEVE_ASSETS_HOME = "RECIEVE_ASSETS_HOME"
export const FAILED_ASSETS_HOME = "FAILED_ASSETS_HOME" 

// fetch about
export const FETCH_ABOUT = "FETCH_ABOUT"
export const RECIEVE_PREFERENCES_ABOUT = "RECIEVE_PREFERENCES_ABOUT"
export const FAILED_PREFERENCES_ABOUT = "FAILED_PREFERENCES_ABOUT"
export const RECIEVE_ASSETS_ABOUT = "RECIEVE_ASSETS_ABOUT"
export const FAILED_ASSETS_ABOUT = "FAILED_ASSETS_ABOUT"

// fetch committee
export const FETCH_COMMITTEE = "FETCH_COMMITTEE"
export const RECIEVE_PREFERENCES_COMMITTEE = "RECIEVE_PREFERENCES_COMMITTEE"
export const FAILED_PREFERENCES_COMMITTEE = "FAILED_PREFERENCES_COMMITTEE"
export const RECIEVE_ASSETS_COMMITTEE = "RECIEVE_ASSETS_COMMITTEE"
export const FAILED_ASSETS_COMMITTEE = "FAILED_ASSETS_COMMITTEE"

// fetch events
export const FETCH_EVENTS = "FETCH_EVENTS"
export const RECIEVE_PREFERENCES_EVENTS = "RECIEVE_PREFERENCES_EVENTS"
export const FAILED_PREFERENCES_EVENTS = "FAILED_PREFERENCES_EVENTS"
export const RECIEVE_ASSETS_EVENTS = "RECIEVE_ASSETS_EVENTS"
export const FAILED_ASSETS_EVENTS = "FAILED_ASSETS_EVENTS"

// fetch live
export const FETCH_LIVE = "FETCH_LIVE"
export const RECIEVE_PREFERENCES_LIVE = "RECIEVE_PREFERENCES_LIVE"
export const FAILED_PREFERENCES_LIVE = "FAILED_PREFERENCES_LIVE"
export const RECIEVE_ASSETS_LIVE = "RECIEVE_ASSETS_LIVE"
export const FAILED_ASSETS_LIVE = "FAILED_ASSETS_LIVE"

// fetch history
export const FETCH_HISTORY = "FETCH_HISTORY"
export const RECIEVE_PREFERENCES_HISTORY = "RECIEVE_PREFERENCES_HISTORY"
export const FAILED_PREFERENCES_HISTORY = "FAILED_PREFERENCES_HISTORY"
export const RECIEVE_ASSETS_HISTORY = "RECIEVE_ASSETS_HISTORY"
export const FAILED_ASSETS_HISTORY = "FAILED_ASSETS_HISTORY"

// fetch notice
export const FETCH_NOTICE = "FETCH_NOTICE"
export const RECIEVE_PREFERENCES_NOTICE = "RECIEVE_PREFERENCES_NOTICE"
export const FAILED_PREFERENCES_NOTICE = "FAILED_PREFERENCES_NOTICE"
export const RECIEVE_ASSETS_NOTICE = "RECIEVE_ASSETS_NOTICE"
export const FAILED_ASSETS_NOTICE = "FAILED_ASSETS_NOTICE"

// fetch bio
export const FETCH_BIOGRAPHY = "FETCH_BIOGRAPHY"
export const RECIEVE_PREFERENCES_BIOGRAPHY = "RECIEVE_PREFERENCES_BIOGRAPHY"
export const FAILED_PREFERENCES_BIOGRAPHY = "FAILED_PREFERENCES_BIOGRAPHY"
export const RECIEVE_ASSETS_BIOGRAPHY = "RECIEVE_ASSETS_BIOGRAPHY"
export const FAILED_ASSETS_BIOGRAPHY = "FAILED_ASSETS_BIOGRAPHY"

// fetch contact
export const FETCH_CONTACT = "FETCH_CONTACT"
export const RECIEVE_PREFERENCES_CONTACT = "RECIEVE_PREFERENCES_CONTACT"
export const FAILED_PREFERENCES_CONTACT = "FAILED_PREFERENCES_CONTACT"
export const RECIEVE_ASSETS_CONTACT = "RECIEVE_ASSETS_CONTACT"
export const FAILED_ASSETS_CONTACT = "FAILED_ASSETS_CONTACT"

// fetch gallery
export const FETCH_GALLERY = "FETCH_GALLERY"
export const RECIEVE_PREFERENCES_GALLERY = "RECIEVE_PREFERENCES_GALLERY"
export const FAILED_PREFERENCES_GALLERY = "FAILED_PREFERENCES_GALLERY"

// fetch hall
export const FETCH_HALL = "FETCH_HALL"
export const RECIEVE_PREFERENCES_HALL = "RECIEVE_PREFERENCES_HALL"
export const FAILED_PREFERENCES_HALL = "FAILED_PREFERENCES_HALL"

//Error handler
export const ERROR_HANDLER = "ERROR_HANDLER"

//Submit enquiry
export const POST_ENQUIRY = "POST_ENQUIRY"
export const RESPONSE_ENQUIRY = "RESPONSE_ENQUIRY"
export const FAILED_ENQUIRY = "FAILED_ENQUIRY"

//Submit analytics
export const POST_ANALYTICS = "POST_ANALYTICS"
export const RESPONSE_ANALYTICS = "RESPONSE_ANALYTICS"
export const FAILED_ANALYTICS = "FAILED_ANALYTICS"