import React from "react";
import DOMPurify from "dompurify";

export const Introduction = (props) => {
  return (
    <section className="ftco-section bg-light py-5">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-12 text-center heading-section ftco-animate">
            <h2 className="mb-2">
              <span className="bg-light">Holy Messages</span>
            </h2>
            <span className="subheading">Message from great one's</span>
          </div>
        </div>
        <div className="row d-flex sermon-wrap mb-5">
          <div className="col-md-6 d-flex align-items-stretch ftco-animate">
            <img
              className="img"
              src={
                "https://server.stcasimirchurchkadavoor.com/" +
                props.bishop.home_bishops_message_photo
              }
            />
          </div>
          <div className="col-md-6 text ftco-animate">
            <h2 className="mb-4 text-md-left text-center">Bishop's Message</h2>
            <div className="meta">
              <p className="mb-0">
                <span className="mb-0 text-md-left text-center">
                  <b className="ptr">
                    {props.bishop.home_bishops_message_name}
                  </b>
                </span>
                <span className="text-md-left text-center mb-0">
                  <b> {props.bishop.home_bishops_message_position}</b>
                </span>
              </p>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.bishop.home_bishops_message_description) }}
            ></div>
            {/* <p className="text-justify mb-0">
              {props.bishop.home_bishops_message_description}
            </p> */}
            {/* <p className="text-justify mb-0">
              On we go to Jerusalem, the city of the house of God Pilgrim people
              we seek the Lord, the Lord of the house of rock".
            </p>
            <p className="text-justify mb-0">
              This familiar liturgical hymn based on the traditions of Song of
              Ascents found in Psalms reminds us that we are a Pilgrim people.
              We are on a Pilgrimage to Heavenly Jerusalem. The picture of
              Pilgrimage reveals the metaphorical image of life which is a
              voyage heavenward. Vatican I1 Lumen Gentium (Dogmatic Constitution
              on the Church) reiterates the Pilgrim dimension of the Church
              (Chapt. VII.)
            </p> */}
          </div>
        </div>
        <div className="row d-flex sermon-wrap mt-5">
          <div className="col-md-6 d-flex align-items-stretch ftco-animate d-block d-md-none">
            <img
              className="img "
              src={
                "https://server.stcasimirchurchkadavoor.com/" +
                props.priest.home_parish_priest_message_photo
              }
            />
          </div>
          <div className="col-md-6 text ftco-animate ">
            <h2 className="mb-2 text-md-right text-center">
              Parish Priest’s message
            </h2>
            <div className="meta">
              <p className="mb-0">
                <span className="mb-0 text-md-right text-center">
                  <b className="ptr ">
                    {props.priest.home_parish_priest_message_name
                      ? props.priest.home_parish_priest_message_name
                      : "Rt. Rev. Fr. Anil Jose"}{" "}
                  </b>
                </span>
                <span className="text-md-right text-center">
                  <b>
                    {props.priest.home_parish_priest_message_position
                      ? props.priest.home_parish_priest_message_position
                      : "Parish Priest, Kadavoor"}
                  </b>
                </span>
              </p>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.priest.home_parish_priest_message_description) }}
            ></div>
            {/* <p className="text-justify mb-0">
              {props.priest.home_parish_priest_message_description}
            </p> */}
            {/* <p className="text-justify mb-0">
              St. Casimir ‘s Church , Kadavoor holds a place of pride as an
              ancient established parish under the Roman Catholic Latin Diocese
              of Quilon. This being the only known Church in India dedicated to
              the Patronage of St. Casimir, it is a matter of great joy to note
              that this Church that houses the shrines of Holy Cross and St.
              George, is elevated to the status of a Diocesan Pilgrimage Center
              through the official Episcopal Declaration on October 7, 2022.
            </p>
            <p className="text-justify mb-0">
              On this auspicious occasion, I wish that this website of the
              Church would become greatly beneficial to the non -resident
              faithful from Kadavoor settled around the world and all the
              devotees of this shrine to continue their fraternal association
              and interaction and take part in the spiritual ceremonies and
              liturgical functions through this web-enabled platform.
            </p> */}
          </div>
          <div className="col-md-6 d-flex align-items-stretch ftco-animate">
            <img
              className="img d-none d-md-block"
              src={
                `${process.env.PUBLIC_URL}` +
                "/assets/images/church/introduction/priest-anil-jose.jpg"
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};
