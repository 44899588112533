import React, { useEffect } from "react";

import { connect } from "react-redux";

import { homeAction, submitAnalytics } from "../../actions";
import {eventsAction,galleryAction} from "../../actions"
import { Services } from "../about/services";
import { SaintQoute } from "../saint-biography/saint-qoute";
import { NextEvent } from "../events/next-event";
import { Events } from "./events";
import { Introduction } from "./intoduction";
import { HomeBanner } from "../../common/banner/home-banner";
import { SaintIntroduction } from "./saint-inroduction";
import ChurchGallery from "./church-gallery";
import { ConferenceHall } from "../parish-hall/conference-hall";
import Loader from "../../common/loader";

const Home = (props) => {
  // const [popup, setPopup] = useState(true);
  // store.dispatch({ type: "RECIEVE_PREFERENCES_HOME", payload: [] });
  useEffect(() => {
    window.scrollTo(0, 0);
    props.submitAnalytics();
    props.homeAction("home");
    props.eventsAction("events_list");
    props.galleryAction("gallery_list");
    // setTimeout(() => {
    //   setPopup(false);
    // }, 15000);
  }, []);

  if (props.home.length !== 0)
    return (
      <div>
        {/* {popup && (
          <div
            style={{
              background: "#000000a1",
              backdropFilter: "blur(4px)",
              position: "fixed",
              zIndex: 99999,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              padding: "11rem",
              justifyContent: "center",
              flexDirection: "column",
            }}
            onClick={() => setPopup(false)}
          >
            <img
              src="/assets/images/church/popup.jpeg"
            ></img>
          </div>
        )} */}
        <HomeBanner
          image={
            props.home.home_banner.welcome_message_banner_cover ? "https://server.stcasimirchurchkadavoor.com/og/" + props.home.home_banner.welcome_message_banner_cover : "/assets/images/church/church10.jpeg"
          }
          banner={props.home.home_banner}
        />
        {console.log("LOOGg",props)}
        <NextEvent
          message={
            props.home.home_banner.welcome_message_heading
              ? props.home.home_banner.welcome_message_heading
              : "Welcome to St. Casimir's church kadavoor Latin Catholic Church -Diocese of Quilon"
          }
          image1={
            props.home.home_banner.welcome_message_banner1
              ?  "https://server.stcasimirchurchkadavoor.com/og/" +props.home.home_banner.welcome_message_banner1
              : "/assets/images/church/conf1.jpeg"
          }
          image2={
            props.home.home_banner.welcome_message_banner2
              ?  "https://server.stcasimirchurchkadavoor.com/og/" +props.home.home_banner.welcome_message_banner2
              : "/assets/images/church/church13.jpeg"
          }
        />
        <SaintQoute
          quote={
            props.home.home_saint_qoute.home_saint_qoute_message
              ? props.home.home_saint_qoute.home_saint_qoute_message
              : 'Since Christ Himself has said, "This is My Body" who shall dare to doubt that It is His Body?'
          }
          name={
            props.home.home_saint_qoute.home_saint_qoute_auther
              ? props.home.home_saint_qoute.home_saint_qoute_auther
              : "Mathew"
          }
        />
        <Services services={null} />
        <Introduction
          bishop={props.home.home_bishops_message}
          priest={props.home.home_parish_priest_message}
        />
        <ConferenceHall hall={props.home.home_conference_hall} numbers={props.home.home_numbers}  />
        {window.counter()}
        {console.log("HOME",props)}
        <SaintIntroduction  saint={props.home.home_pilgrimage_center}/>

        {(props.events && props.events.events_list && props.events.events_list.length) && (<Events events={props.events.events_list} />) }

        {/* <News head="recent events" desc="Our memories" /> */}
        {/* <GalleryContent
          head="Events Gallery"
          desc="Experience God's Presence"
        /> */}
        {console.log('GHG',props.gallery)}
        {(props.gallery  && props.gallery.gallery_list && props.gallery.gallery_list.length) && (<ChurchGallery images={props.gallery}/>) }
      
      </div>
    );
  else return (
    <Loader></Loader>
  );
};

const mapStateToProps = (state) => ({ home: state.content.home, events:state.content.events, gallery: state.content.gallery });

export default connect(mapStateToProps, { homeAction, submitAnalytics, eventsAction, galleryAction })(Home);
