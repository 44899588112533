import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Footer from './common/footer';
import Header from './common/header';
import ScrollToTop from './common/ScrollToTop';
import AboutUs from "./components/about";
import ChurchHistory from "./components/church-history";
import CommiteeMembers from "./components/commitee";
import CountactUs from "./components/contact";
import Events from "./components/events";
import Home from "./components/home";
import ImageGallery from "./components/image-gallery";
import LiveStream from "./components/live-stream";
import NoticeBoard from "./components/notice";
import SaintBiography from "./components/saint-biography";
import ParishHall from './components/parish-hall';
import {useEffect} from 'react';


const App = (props) => {
    useEffect(() => {
        window.counter()
    });

    return (
        <Router>
            <Header/>
            <ScrollToTop />
            <Routes>
                <Route path='/'
                    element={<Home/>}/>
                <Route path='/contact-us'
                    element={<CountactUs/>}/>
                <Route exact path='/live/'
                    element={<LiveStream/>}/>
                <Route path='/live/:id'
                    element={<LiveStream/>}/>
                <Route path='/about-us'
                    element={<AboutUs/>}/>
                <Route path='/church-history'
                    element={<ChurchHistory/>}/>
                <Route path='/committee-members'
                    element={<CommiteeMembers/>}/>
                <Route path='/events'
                    element={<Events/>}/>
                <Route path='/gallery'
                    element={<ImageGallery/>}/>
                <Route path='/notice-board'
                    element={<NoticeBoard/>}/>
                <Route path='/saint-biography'
                    element={<SaintBiography/>}/>
                <Route path='/parish-hall'
                    element={<ParishHall/>}/>
            </Routes>
            <Footer/>
        </Router>
    );
}

export default App;
