import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Banner } from "../../common/banner";
import events from "./event-list.json";
import { UpcomingEvents } from "./upcoming-events";
import {eventsAction} from "../../actions"
import Loader from "../../common/loader";

const Events = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.eventsAction("events_list")
  }, []);

  if(props.event!=0)
  return (
    <div>
      <Banner
        head="Events"
        image={props.image ? props.image : "/assets/images/church/church3.jpg"}
      />
      <section className="ftco-section ftco-degree-bg py-5">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-12 text-center heading-section ftco-animate fadeInUp ftco-animated">
              <h2 className="mb-2">
                <span className="px-4">Recent events</span>
              </h2>
              <span className="subheading">St.Casimir's Kadavoor</span>
            </div>
          </div>
          <div className="row  d-flex justify-content-center">
            <div className="col-lg-12 ftco-animate fadeInUp ftco-animated">
              {console.log("events",props.event.events_list)}
              {props.event.events_list.map((event, i) => (
                    <UpcomingEvents
                      image={event.events_list_image}
                      heading={event.events_list_heading}
                      description={event.events_list_description}
                      time={event.events_list_date}
                      index={i}
                    />
                  ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
  else return (<Loader></Loader>)
};

const mapStateToProps = (state) => ({ event: state.content.events });

export default connect(mapStateToProps, { eventsAction })(Events);
