import React from "react";
import { Link } from "react-router-dom";

const Header = () => {

  return (
    <nav
      className="navbar navbar-dark ftco_navbar bg-dark ftco-navbar-light d-flex justify-content-space"
      id="ftco-navbar"
    >
      <div className="row content-right">
        <div className="col-3">
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <div className="navbar-brand">
              St. Casimir’s Church<span className="my-1">Kadavoor</span>
            </div>
          </Link>
        </div>
        <div className="col-9 d-flex justify-content-end">
          <div className="d-lg-block d-none">
            <Link to={`${process.env.PUBLIC_URL}`}>
              <span className="navbar-toggler">
                <b>Home</b>
              </span>
            </Link>
            <span className="navbar-toggler mx-2">|</span>
            <Link to={`${process.env.PUBLIC_URL}/live`}>
              <span className="navbar-toggler  ">
                <b>Live Mass</b>
              </span>
            </Link>
            <span className="navbar-toggler">|</span>
            <Link to={`${process.env.PUBLIC_URL}/about-us`}>
              <span className="navbar-toggler  ">
                <b>About Us</b>
              </span>
            </Link>
            <span className="navbar-toggler">|</span>
            <Link to={`${process.env.PUBLIC_URL}/church-history`}>
              <span className="navbar-toggler  ">
                <b>History</b>
              </span>
            </Link>
            <span className="navbar-toggler">|</span>
            <Link to={`${process.env.PUBLIC_URL}/Saint-biography`}>
              <span className="navbar-toggler  ">
                <b>Saint biography</b>
              </span>
            </Link>
            <span className="navbar-toggler">|</span>
            <Link to={`${process.env.PUBLIC_URL}/parish-hall`}>
              <span className="navbar-toggler  ">
                <b>Parish Hall</b>
              </span>
            </Link>
            <span className="navbar-toggler">|</span>
            <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
              <span className="navbar-toggler  ">
                <b>Contact us</b>
              </span>
            </Link>
          </div>
          <div className="d-block d-lg-none">
            <button
              className="navbar-toggler d-flex align-items-center"
              type="button"
              data-toggle="collapse"
              data-target="#ftco-nav"
              aria-controls="ftco-nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="pt-1 mr-1">Menu</span>
              <span className="oi oi-menu"></span>
            </button>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav ml-4">
            <li className="nav-item active">
              <Link to="/" className="nav-link"
                              data-toggle="collapse"
                              data-target="#ftco-nav">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/live`}
                className="nav-link"
                data-toggle="collapse"
                data-target="#ftco-nav"
              >
                Live holy Mass
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/about-us`}
                className="nav-link"
                data-toggle="collapse"
                data-target="#ftco-nav"
              >
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/church-history`}
                className="nav-link"
                data-toggle="collapse"
                data-target="#ftco-nav"
              >
                Church History
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/saint-biography`}
                className="nav-link"
                data-toggle="collapse"
                data-target="#ftco-nav"
              >
                Saint Biography
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/events`}
                className="nav-link"
                data-toggle="collapse"
                data-target="#ftco-nav"
              >
                Events
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/parish-hall`}
                className="nav-link"
                data-toggle="collapse"
                data-target="#ftco-nav"
              >
                Parish Hall
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${process.env.PUBLIC_URL}/contact-us`}
                className="nav-link"
                data-toggle="collapse"
                data-target="#ftco-nav"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Header;
