import DOMPurify from "dompurify";
import React from "react";

export const SaintIntroduction = (props) => {
  return (
    <section
      className="ftco-section testimony-section parallax-saint"
      style={{
        backgroundImage:
          "url(" +
          `${process.env.PUBLIC_URL}` +
          "/assets/images/church/saint/background.jpg)",
      }}
    >
      <div className="overlay"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 text-center heading-section heading-section-white ftco-animate">
            <h2 className="mb-2">{props.saint.home_pilgrimage_center_title}</h2>
            <span className="subheading">{props.saint.home_pilgrimage_center_sub_title}</span>
          </div>
        </div>
        <div className="row ftco-animate">
          <div className="col-md-12">
            <div className="row d-flex sermon-wrap mt-5">
              <div className="col-md-6 col-lg-7 text ftco-animate">
              <div className="text-justify mb-0 text-light"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.saint.home_pilgrimage_center_content) }}>

              </div>
                {/* <p className="text-justify mb-0 text-light">
                  Located on the shores of the enchanting Ashtamudi lake of
                  spectacular scenic beauty, St. Casmir’s Church, Kadavoor , a
                  spiritual edifice with architectural splendor, stands
                  elegantly as a site of ancient religious heritage and
                  tradition coupled with a rich legacy of faith of the Catholic
                  community.
                </p>
                <p className="text-justify mb-0 text-light">
                  St. Thomas, one of the twelve Apostles of Jesus, arrived in
                  Kerala in 54 A.D and he is said to have established seven
                  Churches, of which one is said to be in Kollam. The Christian
                  Community of Kadavoor is believed to be a remnant of that
                  ancient community of faithful established by the First Apostle
                  of India. As Kollam was an ancient port town, missionaries
                  also came along with the foreign traders and merchants from
                  different parts of the world. Franciscan and Dominican
                  missionaries strove hard to kindle and sustain Christian faith
                  in this region.
                </p>
                <p className="text-justify mb-0 text-light">
                  It is recorded that in the year 1321, the French Dominican
                  missionary Jordanus Catalani of venerable memory started a
                  mission in Thevally region of the present Kadavoor parish and
                  built a church in the name of St. George. Adjacent to this
                  place of worship, there already existed a Church built in
                  honour of Blessed Virgin Mary called “Tharissa Pally”. Bishop
                  Jordanus Catalani, the first Bishop of Quilon was instrumental
                  in bringing the people from the Nestorian schismatic faith
                  orientations and practices to Roman Catholic faith and
                  pristine Latin traditions of liturgy.
                </p> */}
              </div>
              <div className="col-md-6 col-lg-5 d-flex align-items-stretch ftco-animate">
                <div className="row">
                  <img
                    className="img p-3"
                    src={  "https://server.stcasimirchurchkadavoor.com/" +props.saint.home_pilgrimage_first_image}
                  />
                  <img
                    className="img p-3"
                    src={  "https://server.stcasimirchurchkadavoor.com/" +props.saint.home_pilgrimage_second_image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
