import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Banner } from "../../common/banner";
import { Gallery } from "../../common/gallery";
import json from "./image.json";
import { galleryAction } from "../../actions";
import Loader from "../../common/loader";

const ImageGallery = (props) => {
  console.log(props.gallery);
  const [type, setType] = useState("family-youth");
  const [popup, setPopup] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    props.galleryAction("gallery_list");
  }, []);

  useEffect(() => {
    console.log("Got gallery",props.gallery)
    if (props.gallery.length != 0)
      if (props.gallery.gallery_list.length > 0)
        setType(props.gallery.gallery_list[0].gallery_list_category);
  }, [props.gallery]);

  console.log(props.gallery);
  console.log("Gallery type",type);


  const uniqueCategories=(categories)=>{
    return categories.reduce((a,i)=>{
     if(a){
       if(a.filter(v=>i.gallery_list_category==v.gallery_list_category).length==0)
       return [...a, i];
       else return [...a]
     } 
     return [];
    },[]);
  }

  if (props.gallery.length != 0) {
    return (
      <div>
        {popup && (
          <div
            style={{
              background: "#000000a1",
              backdropFilter: "blur(4px)",
              position: "fixed",
              zIndex: 99999,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              padding: "11rem",
              justifyContent: "center",
              flexDirection: "column",
            }}
            onClick={() => setPopup("")}
          >
            <img style={{height: '90vh',width: 'auto', objectFit: 'contain'}} src={   "https://server.stcasimirchurchkadavoor.com/og/" +popup} alt={popup}></img>
          </div>
        )}
        <Banner
          head="Image Gallery"
          image={
            props.image ? props.image : "/assets/images/church/church2.jpg"
          }
        />
        <div className="container my-5">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-12 text-center heading-section ftco-animate fadeInUp ftco-animated">
              <h2 className="mb-2">
                <span>Image Gallery</span>
              </h2>
              <span className="subheading">St.Casimir's Kadavoor</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <ul className="ministry-list">
                {/* Dummy  */}
                {uniqueCategories(props.gallery.gallery_list).map((list, i) => (
                  <li
                    key={i}
                    className={list.gallery_list_category == type ? "active  " : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => setType(list.gallery_list_category)}
                  >
                    <div>{list.gallery_list_category}</div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-10">
              {console.log("Rendering Gallery", props.gallery)}
              {console.log(
                "Rendering Gallery",
                props.gallery.gallery_list.filter(
                  (data) => data.gallery_list_category == type
                )
              )}
              <div className="row">
                {props.gallery.gallery_list
                  .filter((data) => data.gallery_list_category == type )
                  .map((image, i) => (
                    // <Gallery image={} index={i} column={4} setPopup={setPopup}/>
                    <div
                      className={
                        "ftco-animate fadeInUp ftco-animated p-1 col-md-" +
                        props.column
                      }
                      key={i}
                    >
                      <img
                        className={ "img"+window.innerWidth > 767?" cursor-pointer":""}
                        width="100%"
                        alt={image.gallery_list_image}
                        src={
                          "https://server.stcasimirchurchkadavoor.com/" +
                          image.gallery_list_image
                        }
                        onClick={() => {
                          if (window.innerWidth > 767)
                            setPopup(image.gallery_list_image);
                        }}
                      />
                      {/* <LargeImage popup={popup} image={props.image} setPopup={setPopup} /> */}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }  else return (<Loader></Loader>)
};

const mapStateToProps = (state) => ({ gallery: state.content.gallery });

export default connect(mapStateToProps, { galleryAction })(ImageGallery);
