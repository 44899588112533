import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';

import './index.css';
// import './ck.scss';
import store from './store';
import App from './App';

//live
global.postUrl = 'https://server.stcasimirchurchkadavoor.com/api/';

//local
// global.postUrl = 'http://192.168.43.118:8080/api/';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render (
    <Provider store={store}>
        <App />
    </Provider>);

