import {
    RECIEVE_PREFERENCES_ABOUT,
    RECIEVE_PREFERENCES_BIOGRAPHY,
    RECIEVE_PREFERENCES_COMMITTEE,
    RECIEVE_PREFERENCES_CONTACT,
    RECIEVE_PREFERENCES_EVENTS,
    RECIEVE_PREFERENCES_HISTORY,
    RECIEVE_PREFERENCES_HOME,
    RECIEVE_PREFERENCES_LIVE,
    RECIEVE_PREFERENCES_NOTICE,
    RECIEVE_PREFERENCES_GALLERY,
    RECIEVE_PREFERENCES_HALL

} from "../constants/actionTypes"

const initialState = {
    home: [],
    about: [],
    committee: [],
    live: [],
    notice: [],
    events: [],
    history: [],
    contact: [],
    biography: [],
    gallery:[],
    hall:[]
}

export default function ContentReducer(state = initialState, action) {
    switch (action.type) {
        case RECIEVE_PREFERENCES_HOME:
            return {
                ... state,
                home: action.payload
            }
        case RECIEVE_PREFERENCES_ABOUT:
            return {
                ... state,
                about: action.payload
            }
        case RECIEVE_PREFERENCES_COMMITTEE:
            return {
                ... state,
                committee: action.payload
            }
        case RECIEVE_PREFERENCES_EVENTS:
            return {
                ... state,
                events: action.payload
            }
        case RECIEVE_PREFERENCES_LIVE:
            return {
                ... state,
                live: action.payload
            }
        case RECIEVE_PREFERENCES_HISTORY:
            return {
                ... state,
                history: action.payload
            }
        case RECIEVE_PREFERENCES_NOTICE:
            return {
                ... state,
                notice: action.payload
            }
        case RECIEVE_PREFERENCES_BIOGRAPHY:
            return {
                ... state,
                biography: action.payload
            }
        case RECIEVE_PREFERENCES_CONTACT:
            return {
                ... state,
                contact: action.payload
            }
            case RECIEVE_PREFERENCES_GALLERY:
            return {
                ... state,
                gallery: action.payload
            }
            case RECIEVE_PREFERENCES_HALL:
            return {
                ... state,
                hall: action.payload
            }
            
        default:
            return {
                ... state
            }
    }

}
