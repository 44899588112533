import React from "react";
import DOMPurify from "dompurify";


export default History = (props) => {
  return (
    <section className="ftco-section py-5">
      <div className="container">
        <div className="row mb-5 pb-3">
          <div className="col-md-12 text-center heading-section ftco-animate fadeInUp ftco-animated">
            <h2 className="mb-2">
              <span className="px-4">History</span>
            </h2>
            <span className="subheading">St.Casimir's Kadavoor</span>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          {/* {props.history &&
            props.history.map((history, i) => (
              <div className="col-md-12 mb-5 " key={i}>
                <h3 className="mb-4">{history.heading}</h3>
                <p className="text-justify">{history.description}</p>
                <div className="row mb-5">
                  <div className="col-12 col-md-6 p-2">
                    <img src={history.image1} width="100%" />
                  </div>
                  <div className="col-12 col-md-6 p-2">
                    <img src={history.image2} width="100%" />
                  </div>
                </div>
                <ul className="ministry-list my-5">
                  {history.lists &&
                    history.lists.map((item, i) => (
                      <li>
                        <span className="ion-ios-arrow-forward mr-2"></span>
                        {item}
                      </li>
                    ))}
                </ul>
                <hr />
              </div>
            ))} */}

          <div className="col-md-12 mb-5" 
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.history.church_history_content) }}
          ></div>

          {/* <div className="col-md-12 mb-5 ">
            <h3 className="mb-4">Historical ‘Muthappan Cross ‘</h3>
            <p className="text-justify">
              When the Portugeese missionaries arrived in 1502, there was a
              significant revival of faith. At that time there were about 25000
              christians in Kollam. In the religious persecution unleashed by
              religious fanatics in Kollam in the later periods, about 9 priests
              and 3000 Christians were killed and they became martyrs of faith.
              A stone Cross was erected in Kadavoor church premises to
              commemorate this martyrdom of great number of people and priests
              and subsequently this cross was widely venerated by the faithful
              christening it as ‘Muthappan Cross’ . It was originally erected
              for worship by the faithful in Kadavoor who numbered around 100 at
              the time of its establishment. This cross attracts a large number
              of devotees daily who light candles and oil lamps in this Cross of
              worship and adoration. Blessings abound here and the devotees from
              all sects and faiths have many testimonies to support their ardent
              devotion to the miraculous Cross.
            </p>
          </div> */}
        </div>
      </div>
    </section>
  );
};
