import * as types from "../constants/actionTypes"
import {fetchAssets, fetchPreferences, submitEnquiryService, submitAnalyticsService} from "../services"

const errorHandler = error => {
    return dispatch => {
        dispatch({type: types.ERROR_HANDLER, error: error});
    }
}

//home
export function homeAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_HOME});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_HOME, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_HOME});
            }
        }).catch(errorHandler);
    };
}

//live

export function liveAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_LIVE});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_LIVE, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_LIVE});
            }
        }).catch(errorHandler);

    };
}

//About us

export function aboutUsAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_ABOUT});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_ABOUT, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_ABOUT});
            }
        }).catch(errorHandler);

    };
}

//contact us

export function contactUsAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_CONTACT});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_CONTACT, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_CONTACT});
            }
        }).catch(errorHandler);

    };
}

//committee

export function committeeAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_COMMITTEE});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_COMMITTEE, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_COMMITTEE});
            }
        }).catch(errorHandler);

    };
}

//events

export function eventsAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_EVENTS});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_EVENTS, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_EVENTS});
            }
        }).catch(errorHandler);

    };
}

//church history

export function churchHistoryAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_HISTORY});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_HISTORY, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_HISTORY});
            }
        }).catch(errorHandler);

    };
}

//notice board

export function noticeAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_NOTICE});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_NOTICE, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_NOTICE});
            }
        }).catch(errorHandler);

    };
}

//Biography

export function biographyAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_BIOGRAPHY});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_BIOGRAPHY, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_BIOGRAPHY});
            }
        }).catch(errorHandler);

    };
}

//Gallery

export function galleryAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_GALLERY});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_GALLERY, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_GALLERY});
            }
        }).catch(errorHandler);

    };
}

//PaRISH HALL

export function hallAction(tag) {
    return dispatch => {
        dispatch({type: types.FETCH_HALL});
        fetchPreferences(tag).then(response => {
            if (response.data) {
                return dispatch({type: types.RECIEVE_PREFERENCES_HALL, payload: response.data});
            } else {
                return dispatch({type: types.FAILED_PREFERENCES_HALL});
            }
        }).catch(errorHandler);

    };
}

// submit enquiry
export function submitEnquiry(data) {
    return dispatch => {
        dispatch({type: types.POST_ENQUIRY});
        submitEnquiryService(data).then(response => {
            if (response.data) {
                if (response.data.success === true) {
                    return dispatch({type: types.RESPONSE_ENQUIRY, payload: response.data});
                } else {
                    return dispatch({type: types.FAILED_ENQUIRY});
                }
            } else {
                return dispatch({type: types.FAILED_ENQUIRY});
            }
        }).catch(errorHandler);
    };
}

// submit Analytics
export function submitAnalytics() {
    let data = {
        name: "visitor",
        data: ""
    }
    return dispatch => {
        dispatch({type: types.POST_ANALYTICS});
        submitAnalyticsService(data).then(response => {
            if (response.data) {
                if (response.data.success === true) {
                    return dispatch({type: types.RESPONSE_ANALYTICS, payload: response.data});
                } else {
                    return dispatch({type: types.FAILED_ANALYTICS});
                }
            } else {
                return dispatch({type: types.FAILED_ANALYTICS});
            }
        }).catch(errorHandler);
    };
}
