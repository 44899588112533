import React from "react";
import { Link } from "react-router-dom";

const Loader = () => {
    const style = {
        zIndex:9999,
        background: "#ffffffb8",
        backdropFilter: "blur(3px)"
    }
  return (
    <div className="vh-100 w-100 d-flex justify-content-center align-items-center position-fixed" style={style}>
      <div>
        <div className="d-flex align-items-center">
          <div>
            <div class="navbar-brand px-2 border-right mx-2 text-dark">
              St. Casimir’s Church<span class="my-1 text-right">Kadavoor</span>
            </div>
          </div>
          <div class="spinner-border text-dark" role="status"></div>
        </div>
        {/* <div className="text-right">
          <small>
            <b>LOADING CONTENT</b>
          </small>
        </div> */}
      </div>
    </div>
  );
};
export default Loader;
