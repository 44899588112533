import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Banner } from "../../common/banner";
import { Biography } from "./biography";
import { biographyAction } from "../../actions";
import Loader from "../../common/loader";


const SaintBiography = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.biographyAction("saint_biography")
  }, []);

  // console.log(props.biography)
  if(props.biography!=0)
  return (
    <div>
      <Banner
        head="Saint Biography"
        image={props.image ? props.image : "/assets/images/church/church5.jpg"}
      />
      <Biography
        image={props.image ? props.image : "/assets/images/church/saint/saint.jpg"}
        biography={props.biography.saint_biography}
      />
    </div>
  );
  else return (<Loader></Loader>)
};

const mapStateToProps = (state) => ({ biography: state.content.biography });

export default connect(mapStateToProps, { biographyAction })(SaintBiography);
